import styled from "styled-components";

const Root = styled.footer`
  display: flex;
  background-color: #361534;
  width: 100%;
  height: 55px;
  padding: 18px 10px;
  flex: 0 0 auto;
  margin-top: 40px;
`;

const FooterContent = styled.div`
  display: flex;
  max-width: 960px;
  width: 100%;
  margin-inline: auto;

  p {
    font-size: 14px;
    color: #fff;
    font-weight: 300;
  }
`;

export const Footer = () => {
  return (
    <Root>
      <FooterContent>
        <p>Copyright 2024 Dana Rosser | All Rights Reserved</p>
      </FooterContent>
    </Root>
  );
};
